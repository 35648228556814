import React from "react";
import { NavLink } from "react-router-dom";

const GetACallBackButton = () => {
  return (
    <NavLink to="/contact-us" className="hidden sm:hidden lg:block">
      <button
        style={{
          width: "160px",
          height: "38px",
          borderRadius: "10px",
          opacity: "0px",
          color: "white",
        }}
        className="bg-primary-500 text-white text-[14px]"
      >
        Get a Call Back
      </button>
    </NavLink>
  );
};

export default GetACallBackButton;
