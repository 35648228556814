import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCrCjck3nn95RW0kggu8HpZGJZjTeN1LpA",
  authDomain: "aiqahealth-new-landing-portal.firebaseapp.com",
  projectId: "aiqahealth-new-landing-portal",
  storageBucket: "aiqahealth-new-landing-portal.appspot.com",
  messagingSenderId: "63626076973",
  appId: "1:63626076973:web:8ee444c1002c6aea11a9a4",
  measurementId: "G-NYLMEV9D2E",
};

export const firebaseApp = initializeApp(firebaseConfig);

// Messaging service

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();
