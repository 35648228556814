import React, { useEffect, useRef, useState } from "react";
import AiqaLogo from "../assets/common/aiqahealth.svg";
import GetACallBackButton from "./common/GetACallBackButton";
import CustomNavLink from "./common/CustomNavLink";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const currentPath = location.pathname + location.hash;

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("overflow-hidden");
    };
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const navItems = [
    { to: "/", label: "Home" },
    { to: "/about-us", label: "About Us" },
    { to: "/#our-plan", label: "Our Plans" },
    { to: "/become-reseller", label: "Become a Reseller" },
    { to: "/contact-us", label: "Get a Call Back" },
  ];

  return (
    <>
      <div className="h-20 md:h-20 z-50 hidden lg:block">
        <div className="fixed z-50 top-0 w-full bg-white transition-all duration-500 bg-opacity-100">
          <div className="w-full md:max-w-[1248px] flex lg:items-center justify-between mx-auto py-4 lg:flex-row md:flex-col md:items-start lg:gap-0 md:gap-2 md:px-5 lg:px-0">
            <CustomNavLink exact={true} to="/">
              <img src={AiqaLogo} alt="" />
            </CustomNavLink>
            <div className="hidden w-full md:block md:w-auto">
              <ul className="md:flex space-x-8 rtl:space-x-reverse text-[14px]">
                {navItems.slice(0, 4).map(({ to, label }) => (
                  <li key={to}>
                    <CustomNavLink exact={true} to={to}>
                      {label}
                    </CustomNavLink>
                  </li>
                ))}
              </ul>
            </div>
            <GetACallBackButton />
          </div>
        </div>
      </div>

      <div className="h-20 z-50 lg:hidden">
        <div className="fixed z-50 top-0 w-full bg-white transition-all duration-500 bg-opacity-100">
          <div className="flex items-center justify-center mx-auto p-4 relative">
            <button
              ref={buttonRef}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 absolute left-2 top-5"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path stroke="currentColor" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
            <CustomNavLink exact={true} to="/">
              <img src={AiqaLogo} alt="" />
            </CustomNavLink>
            <div
              className={`fixed top-0 left-0 h-full w-[100%] bg-white transition-transform transform ${
                open ? "translate-x-0" : "-translate-x-full"
              } z-40`}
              ref={menuRef}
              onScroll={(e) => e.preventDefault()}
            >
              <div className="relative flex items-center justify-center p-5">
                <CustomNavLink exact={true} to="/">
                  <img src={AiqaLogo} alt="" />
                </CustomNavLink>
                <button
                  type="button"
                  className="absolute right-2 inline-flex items-center p-2 w-10 h-10 justify-center text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                  onClick={() => setOpen(false)}
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path stroke="currentColor" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <ul className="flex flex-col rounded-lg bg-gray-50 px-5">
                {navItems.slice(0, 9).map(({ to, label }) => (
                  <li
                    key={to}
                    className={`py-4 ${
                      open && currentPath === to ? "text-red-500" : ""
                    }`}
                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                  >
                    <CustomNavLink exact={true} to={to}>
                      {label}
                    </CustomNavLink>
                  </li>
                ))}
              </ul>
            </div>
            <GetACallBackButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
