import React, { Suspense, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Lottie from "lottie-react-web";
import Layout from "./layout/Layout";
import loaderAnimation from "./assets/lottyAnimation/loader.json";
import PageNotFound from "./pages/PageNotFound";
import "./app.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.compat.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  getToken,
  onMessage,
  getMessaging,
  isSupported,
} from "@firebase/messaging";
import { messaging } from "./firebase";
const Home = React.lazy(() => import("./pages/Home"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const BecomeReseller = React.lazy(() => import("./pages/BecomeReseller"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const CustomerGrievancesPolicy = React.lazy(() => import("./pages/Grievances"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions")
);
const Grievances = React.lazy(() => import("./pages/CustomerGrievancesPolicy"));
const AiqaTransform = React.lazy(() => import("./pages/AiqaTransform"));
const DigitalHealthSubscription = React.lazy(() =>
  import("./pages/DigitalHealthSubscription")
);
const CustomerSupport = React.lazy(() => import("./pages/CustomerSupport"));
const InvestorPortal = React.lazy(() => import("./pages/InvestorPortal"));
const KnowMorePlan = React.lazy(() => import("./pages/KnowMorePlan"));
const FAQ = React.lazy(() => import("./components/FAQ"));

const LoadingCompo = () => {
  return (
    <div className="w-screen h-screen">
      <Lottie
        options={{
          animationData: loaderAnimation,
          loop: true,
          rendererSettings: {},
        }}
      />
    </div>
  );
};

function App() {
  const getRequestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(await messaging, {
          vapidKey:
            "BEBPuSYpZwV0LVUcvlS6qOoxYVwa35nlLymbV7fnSofvDCcvWXg74gb5gdzczbLIbcgZ72PxzZCk0IlEuYm5IKw",
        });
        if (token) {
          localStorage.setItem("fcm-token", token);
        }
      } else if (Notification.permission === "denied") {
        console.log("Permission is denied");
      }
    } catch (error) {
      console.error("Error getting FCM token", error);
    }
  };

  const onMessageListener = async () => {
    getRequestPermission();
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      onMessage(getMessaging(), (payload) => {
        if (payload?.notification?.title === "Update_Available") {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    onMessageListener();
  }, []);

  const layoutWrapper = ({ ...children }) => {
    return <Layout>{children}</Layout>;
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: layoutWrapper(<Home />),
    },
    {
      path: "/our-plan/:plan",
      element: layoutWrapper(<KnowMorePlan />),
    },
    {
      path: "/about-us",
      element: layoutWrapper(<AboutUs />),
    },
    {
      path: "*",
      element: layoutWrapper(<PageNotFound />),
    },
    {
      path: "/become-reseller",
      element: layoutWrapper(<BecomeReseller />),
    },
    {
      path: "/contact-us",
      element: layoutWrapper(<ContactUs />),
    },
    {
      path: "/privacy-policy",
      element: layoutWrapper(<PrivacyPolicy />),
    },
    {
      path: "/customer-grievances-policy",
      element: layoutWrapper(<CustomerGrievancesPolicy />),
    },
    {
      path: "/terms-and-conditions",
      element: layoutWrapper(<TermsAndConditions />),
    },
    {
      path: "/grievances-redressal",
      element: layoutWrapper(<Grievances />),
    },
    {
      path: "/aiqa-transform",
      element: layoutWrapper(<AiqaTransform />),
    },
    {
      path: "/digital-health-subscription",
      element: layoutWrapper(<DigitalHealthSubscription />),
    },
    {
      path: "/investor-portal",
      element: layoutWrapper(<InvestorPortal />),
    },
    {
      path: "/customer-support",
      element: layoutWrapper(<CustomerSupport />),
    },
    {
      path: "/faqs",
      element: layoutWrapper(<FAQ />),
    },
  ]);

  return (
    <Suspense fallback={<LoadingCompo />}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </Suspense>
  );
}

export default App;
