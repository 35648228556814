import React from "react";
import insta from "../assets/common/instaLogo.svg";
import facebook from "../assets/common/facebookLogo.svg";
import linkedin from "../assets/common/linkedinLogo.svg";
import { NavLink } from "react-router-dom";
import CustomNavLink from "./common/CustomNavLink";
import phone from "../assets/contact_us/carbon_phone-filled-red.svg";
import mail from "../assets/contact_us/fluent_mail-20-filled-red.svg";
import AiqaLogo from "../assets/common/aiqahealthWhiteLogo.svg";

const Footer = () => {
  const data = [
    {
      img: insta,
      link: "https://www.instagram.com/aiqahealth/profilecard/?igsh=Y3J1aHd6bHR3bXZs",
    },
    { img: facebook, link: "https://fb.watch/aAJb6ruaIo/" },
    {
      img: linkedin,
      link: "https://www.linkedin.com/posts/doctoroncallindia_commoncold-cough-covidguidelines-activity-6888345117518913536-Js9h",
    },
  ];
  return (
    <div className="bg-primary-500 text-white flex justify-center">
      <div className="w-full md:max-w-[1248px] footer-wrapper px-5 lg:p-0">
        <div className="flex justify-between gap-4 my-5 md:my-[34.5px] items-center">
          <CustomNavLink exact={true} to="/">
            <img src={AiqaLogo} alt="" />
          </CustomNavLink>
          <div className="flex gap-5 ">
            {data.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    key={index}
                    className="bg-primary-400 h-8 w-8 md:h-12 md:w-12 flex justify-center items-center rounded-full"
                  >
                    {" "}
                    <img
                      style={{ width: "13px", height: "16px" }}
                      alt=""
                      src={item.img}
                    />
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <hr className="w-full md:mb-10 mb-5" />
        <div className="flex flex-wrap justify-between md:mb-8 mb-5">
          <div className="w-full lg:w-[25%] pl-0 mb-5 lg:mb-0">
            <div className="w-full lg:w-64">
              <p className="para text-white leading-6">
                Hospido private limited, 5th floor, SAS tower, sector 38,
                Gurugram, Haryana, 122001
              </p>
            </div>
            <div className="mt-5">
              <div className="flex items-center">
                <span className="bg-white rounded-full w-8 h-8 mr-3 p-2">
                  <img src={phone} alt="" />
                </span>
                <a href="tel:9105556666">+91 6262 306 306</a>
              </div>
              <div className="flex items-center mt-5">
                <span className="bg-white rounded-full w-8 h-8 mr-3 p-2">
                  <img src={mail} alt="" />
                </span>
                <a href="mailto: care@aiqahealth.com">care@aiqahealth.com</a>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[75%] gap-2 flex flex-col md:flex-row justify-between md:mt-0 para text-white">
            <div className="flex md:gap-y-3 gap-y-2 flex-col mb-5 md:mb-0">
              <div className="heading3 text-[#FFFFFF]">Quick Links</div>
              <div className="footer-links">
                <NavLink to="/" className="para text-white leading-6">
                  Home
                </NavLink>
                <NavLink to="/#our-plan" className="para text-white leading-6">
                  Our Plan
                </NavLink>
                <NavLink
                  to="/become-reseller"
                  className="para text-white leading-6"
                >
                  Become a Reseller
                </NavLink>
                <NavLink to="/faqs" className="para text-white leading-6">
                  FAQs
                </NavLink>
                <NavLink to="/contact-us" className="para text-white leading-6">
                  Get a Call Back
                </NavLink>
              </div>
            </div>
            <div className="flex md:gap-y-3 gap-y-2 flex-col mb-5 md:mb-0">
              <div className="heading3 text-[#FFFFFF]">Company Information</div>
              <div className="footer-links">
                <NavLink to="/about-us" className="para text-white leading-6">
                  About Us
                </NavLink>
                <NavLink
                  to="/privacy-policy"
                  className="para text-white leading-6"
                >
                  Privacy Policy
                </NavLink>
                <NavLink
                  to="/terms-and-conditions"
                  className="para text-white leading-6"
                >
                  Terms & Conditions
                </NavLink>
                <NavLink
                  to="/grievances-redressal"
                  className="para text-white leading-6"
                >
                  Grievance Redressal
                </NavLink>
                <NavLink
                  to="/customer-grievances-policy"
                  className="para text-white leading-6"
                >
                  Customer Grievances Policy
                </NavLink>
              </div>
            </div>
            <div className="flex md:gap-y-3 gap-y-2 flex-col">
              <div className="heading3 text-[#FFFFFF]">
                Support and Resources
              </div>
              <div className="footer-links">
                <NavLink
                  to="/customer-support"
                  className="para text-white leading-6"
                >
                  Customer Support
                </NavLink>
                <NavLink
                  to="/investor-portal"
                  className="para text-white leading-6"
                >
                  Investor Portal
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pb-2 px-10 para text-white lg:block" hidden>
          Insurance products are offered and serviced by Hospido Private Limited
          | IRDAI Registration Code: CA0829, License category - Corporate Agent
          (Composite), Valid till 07-12-2025 | CIN: U85300DL2020PTC364444
        </div>
        <div className="text-center para md:mb-6 mb-5 text-white">
          © aiqahealth 2024 - All Rights Reserved
        </div>
      </div>
    </div>
  );
};
export default Footer;
